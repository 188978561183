<template>
  <main class="main">
    <MyHeader />

    <section class="section help">
      <div class="wrap">

          

          <h2>HiMoRNA help page </h2>
          <p class="text">Schematic overview of the HiMoRNA website navigation and its integration with the RNA-Chrom-DB web servers</p>
          <img src="@/assets/images/Overview.png" style="width:800px; display: block; margin: 0 auto;">
          
          <div class="help-tabs">
              <div class="help-tabs__tab" @click="setTab(1)" :class="{active: active === 1}">
                  <h4>1. Start Page (Dashboard)</h4>
                  <p>The main page of HiMoRNA allows users to download database, lncRNAs, and genes. </p>
              </div>
              <div class="help-tabs__tab" @click="setTab(2)" :class="{active: active === 2}">
                  <h4>2. Search Page</h4>
                  <p>The search page enables users to apply filters such as lncRNAs, histone
                     modifications, genes, genomic coordinates, and correlation thresholds. </p>
                  <p><b>Mandatory Filters:</b></p>
                  <b>Adding lncRNAs:</b>
                  <p>To include lncRNAs in your search, you can either type the Ensembl ID(s) (e.g., ENSG00000268020, but not ENSG00000268020.3) or the names of lncRNAs individually, pressing Enter after each entry. 
                     Alternatively, you can upload a file containing a list of lncRNAs, with each entry in a separate line. Please note that due to discrepancies in naming or identification, 
                     your input might not directly match entries in HimoRNA database. We recommend downloading the "lncRNA Correspondence Table" from our homepage for assistance. 
                     This can help you locate your gene using genomic coordinates or confirm its absence in our database.</p>
                  <b>Adding Histone Modifications:</b>
                  <p>ChIP-seq data was used to identify peaks representing regions of the genome enriched with specific histone modifications.
                  Select some or all modifications.</p>
                  <p><b>Optional Filters:</b></p>
                  <b>Gene/Gene ID:</b>
                  <p>To identify genes associated with specific modifications within their body or in regions up to 1000 base pairs (bp) adjacent to them,
                     you can input gene identifiers ( Ensembl IDs) or names individually by pressing Enter after each entry, or by uploading 
                     a file that contains a list of genes, each on a separate line.</p>
                     <p>It's important to note that the gene IDs or names you provide may not directly match those in the HiMoRNA database
                     due to various discrepancies that can arise. To assist with this, we recommend downloading the 'Gene Table'
                     available on our homepage. This table can be used to cross-reference genomic coordinates and help you accurately
                     locate your gene of interest or confirm if it is not present in HiMoRNA.</p>
                  <b>Genomic Coordinates:</b>
                  <p>Genomic coordinates to search for histone modification peaks. Input tab-separated coordinates in bed format, each on a new line. Can also be provided with a txt file in the same format.</p>
                  <b>Correlation Threshold:</b>
                  <p>This filter allows you to set a minimum Spearman correlation threshold, enabling you to exclude data below this value from your search results.</p>
              </div>
              <div class="help-tabs__tab" @click="setTab(3)" :class="{active: active === 3}">
                  <h4>3. Results Page</h4>
                  <p>The search result page typically includes information about the correlated lncRNA-genomic loci pairs. 
                    The table includes columns:  histone modification name, lncRNA name, chromosome, start and end coordinates of the correlated loci (ChIP-seq peak), HM-associated gene name, and Spearman correlation coefficient value.
                    Additionally, there are links provided to navigate to the RNA-Chrom-DB. HiMoRNA generates a request to the RNA-Chrom DB 
                    for a given RNA with URL (e.g. https://rnachrom2.bioinf.fbb.msu.ru/basic_graphical_summary_dna_filter?locus=chrX:23456-24253566&name=XIST&rnaID=227896&organism=Homo+sapiens) 
                    containing the necessary parameters (locus, name, rnaID, organism). There are three buttons which redirect to RNA-Chrom DB, given X-lncRNA and peak Y: \
                    All X-lncRNA contacts; X-lncRNA contacts with the peak Y locus; All RNAs that have contacts with the peak Y locus.</p>
                  
                  <p>Users can navigate from the search result page to more detailed pages related to specific histone modifications, lncRNAs, genes, or correlations. 
                     By clicking on these elements in the search results, users can access additional metadata, visualizations, and information for further exploration.</p>
              </div>
              <div class="help-tabs__tab" @click="setTab(4)" :class="{active: active === 4}">
                  <h4>4. Histone Modifications Page</h4>
                  <p>The histone modifications (HM) page showcases the 
                     distribution of Histone Modifications (HM) peaks and their correlations with RNAs and genes associated with the peaks. </p>
              </div>
              <div class="help-tabs__tab" @click="setTab(5)" :class="{active: active === 5}">
                  <h4>5. lncRNA Page</h4>
                  <p>The LncRNA page provides information about its expression in all cell types used in HiMoRNA, 
                     per chromosome distribution of correlated peaks for each histone modification separately and a summary
                      table of all peaks correlated with this lncRNA.</p>
              </div>
              <div class="help-tabs__tab" @click="setTab(6)" :class="{active: active === 6}">
                  <h4>6. Modification-associated Gene Page</h4>
                  <p>This page provides information about  modification-associated gene expression in all cell types used in HiMoRNA, 
                    a table of all histone modification peaks associated with the gene and a summary table of all peaks associated with this gene 
                    and at the same time correlated with any lncRNA.</p>
              </div>              
              <div class="help-tabs__tab" @click="setTab(6)" :class="{active: active === 6}">
                  <h4>7. Correlation Page</h4>
                  <p>The correlation page exhibits a distribution of tissue-specific lncRNA 
                     RPKMs based on the peak signal in the same tissue, highlighting a Spearman correlation coefficient.</p>
                  <p>Significant correlations indicate potential associations between lncRNA expression and histone modifications.</p>
              </div>
          </div>
      </div>
    </section>
    <MyFooter />
  </main>
</template>

<script>
import MyHeader from '@/components/MyHeader';
import MyFooter from '@/components/MyFooter';

export default {
  name: 'dashboard',
  components: {
    MyHeader,
    MyFooter,
  },
  data() {
    return {
      active: null,
    }
  },
  methods: {
      setTab(number) {
          this.active = this.active === number ? null : number;
      }
  }
}

</script>
